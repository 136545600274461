import { render, staticRenderFns } from "./top-nav-bar.vue?vue&type=template&id=47e63e35&scoped=true&lang=pug"
var script = {}
import style0 from "./top-nav-bar.vue?vue&type=style&index=0&id=47e63e35&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47e63e35",
  null
  
)

export default component.exports